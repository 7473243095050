@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include sm {
    .cardPickerItems {
      justify-content: space-around;
    }
  }
}

.title {
  @include body-text('lg', 700);
  font-size: 30px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 32px;
}

.inputs__blue,
.inputs__dark,
.inputs__purple {
  .input::placeholder {
    color: $black10;
  }
}

.inputs__default {
  .input::placeholder {
    color: $black40;
  }
}

.inputContainer {
  margin: 24px 0;
}

.input {
  border-radius: 12px;
  width: 100%;
  height: 48px;
  border: 0;
}

.wholeInput {
  margin: 24px 0;
}

.label {
  margin-bottom: 12px;
}

.cardPickerItems {
  display: flex;
  justify-content: space-between;
}

.cardItem {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.card {
  cursor: pointer;
  border: 2.5px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 88px;
  height: 48px;
  margin-bottom: 8px;
  -webkit-tap-highlight-color: transparent;

  svg {
    width: 48px;
    border-radius: 12px;
  }
}

.card__blue {
  background-color: $blue300;
}

.card__purple {
  background-color: $purple300;
}

.card__dark {
  background-color: rgba(242, 244, 247, 0.6);
}

.card__default {
  background-color: $black6;
}

.card.selected {
  border: 2.5px solid $black3;
}

.card.selected.card__default {
  border: 2.5px solid $blue500;
}

.cardColor {
  @include body-text('sm', 400);
}

.previewContainer {
  position: relative;
  z-index: 1;
  height: 210px;
}

.cardPreview {
  margin: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 170px;
}

.flipCardBackground {
  position: absolute;
  background-color: $black3;
  opacity: 0.15;
  filter: blur(17px);
  width: 100%;
  height: 120%;
  border-radius: 50%;
}

.flipCard {
  cursor: pointer;
  width: 293px;
  height: 180px;
  background-color: transparent;
  perspective: 1000px;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;
  -webkit-cursor: grab;
  transform-style: preserve-3d;
  user-select: none;
  -webkit-user-select: none;
}

.flipCardInner:active {
  user-select: none;
  -webkit-user-select: none;
}

.flipCardInnerBack {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
}

.flipCardBack {
  transform: rotateY(180deg);
}

.cardOwnerName {
  color: $white;
  position: absolute;
  text-transform: uppercase;
  @include body-text('md', 300);

  &.white {
    color: $black80;
  }

  &.purple_silver,
  &.gold,
  &.light_purple {
    font-size: 12px;
    letter-spacing: 1px;
  }

  &.purple_silver {
    color: #d8d8d8;
  }

  &.gold {
    color: #e0ca91;
  }

  &.light_purle {
    color: $white;
  }
}

.frontName {
  right: 6%;
  bottom: 25%;

  &.purple_silver,
  &.gold,
  &.light_purple {
    left: 6.8%;
    bottom: 10.9%;
    right: auto;
  }
}

.backName {
  @include body-text('xs', 300);
  left: 8%;
  bottom: 30%;
}

.ellipseContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;

  &.blue,
  &.dark {
    .ellipse stop {
      stop-color: $white;
    }

    .handPointingContainer {
      background-color: $white;

      .handPointing path {
        fill: $deepBlue;
      }
    }
  }

  &.purple {
    .ellipse stop {
      stop-color: $white;
    }

    .handPointingContainer {
      background-color: $white;

      .handPointing path {
        fill: $purple500;
      }
    }
  }
}

.handPointingContainer {
  position: absolute;
  bottom: -20px;
  width: 40px;
  height: 40px;
  background: $deepBlue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: $white;
    width: 20px;
    height: 20px;
  }
}

.button {
  margin-top: 56px;
  width: 100%;
  height: 48px;
  border-radius: 12px;
  border: 0;
  text-transform: uppercase;
  @include body-text('lg', 700);
  cursor: pointer;
}
